AFRAME.registerComponent('marker-angle-sensor', {
  schema: { 
    axis: {type: 'string'}
  },
  
  init: function() {
    this.marker = this.el;
    
    if (this.data.axis == 'x' || this.data.axis == 'y' || this.data.axis == 'z') {
      this.axis = this.data.axis;
    }

    this.marker.addEventListener('markerFound', () => { this.markerFound = true; });
    this.marker.addEventListener('markerLost', () => { 
      this.markerFound = false; 
      this.el.emit('angle-lost');
    });

    this.el.emit('marker-angle-sensor-ready', {sensor: this});
  },

  tick: function() {
    if (!this.markerFound) {
      return;
    }

    if (this.initialQuaternion == null) {
      this.initialQuaternion = this.marker.object3D.quaternion.clone();
      return;
    }

    if (this.axis == null) {
      let angle = this.marker.object3D.quaternion.angleTo(this.initialQuaternion) * 180 / Math.PI;
      this.el.emit('angle-found', { angle: angle });
      return;
    }

    const relativeEuler = this.computeRelativeEulerAngles();
    const angle = this.computeAngleAroundAxis(relativeEuler, this.axis) * 180 / Math.PI;

    this.el.emit('angle-found', { angle: angle })
  },
  

  computeRelativeEulerAngles: function() {
    const inverseInitialQuaternion = this.initialQuaternion.clone().invert();

    const relativeQuaternion = new THREE.Quaternion()
      .multiplyQuaternions(inverseInitialQuaternion, this.marker.object3D.quaternion);

    return new THREE.Euler().setFromQuaternion(relativeQuaternion);
  },

  computeAngleAroundAxis: function(eulerAngles, axis) { 
    switch(axis) {
      case 'x':
        return Math.abs(eulerAngles.x);
      case 'y':
        return Math.abs(eulerAngles.y);
      case 'z':
        return Math.abs(eulerAngles.z);
      default:
        throw new Error("Unexpected value of axis: " + axis);
    }
  }
});
